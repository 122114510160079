import React, { useEffect, useState } from 'react'
import { Form, Input, Icon, Button, Message } from 'semantic-ui-react';
import styled from 'styled-components';
import MemberService from '../../services/MemberService';
import { useAuth } from '../../hooks/use-auth';
import isEmail from 'validator/lib/isEmail';

const Container = styled.div`
  color: white;
  max-width: 600px;
  margin: 0 auto;
  padding: 20px;
  text-align: center;

  h2, h3 {
    margin: 0;
  }

  display: flex;
  flex-direction: column;
  gap: 15px;
`

function SubscribeToNewsletter() {
  const [email, setEmail] = useState('');
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [isSubmitted, setIsSubmitted] = useState(false);
  const [errorMessage, setErrorMessage] = useState(null);
  const auth = useAuth();

  useEffect(() => {
    if (auth.user && auth.user.authenticated) {
      setEmail(auth.user.email);
    }
    else { //Set name and email from local storage for guest user
      setEmail(localStorage.getItem('enquiryEmail') || '');
    }
  }, []);

  return <Container>
    {isSubmitted
      ? <h2>Thanks for subscribing!</h2>
      : <>
        <h2><Icon name='mail' /> Subscribe</h2>
        <h3>for the latest listings and more</h3>
        <Form inverted size='large'
          onSubmit={
            async () => {
              if (!isEmail(email)) {
                setErrorMessage({
                  title: 'Your email address appears to be invalid',
                  body: 'Please check it and try again'
                })
              }
              else {
                setErrorMessage(null);
                setIsSubmitting(true);
                await MemberService.subscribeEmail(email);
                setIsSubmitted(true);
              }
            }}>
          <Form.Group>
            <Form.Field
              control={Input}
              placeholder='Email'
              width={12}
              value={email}
              onChange={(_, data) => setEmail(data.value)}
              autoComplete='email'
              icon='mail'
              iconPosition='left'
            />
            <Form.Field
              control={Button}
              fluid
              primary
              width={4}
              size='large'
              loading={isSubmitting}
              disabled={isSubmitting}>
              Subscribe
            </Form.Field>
          </Form.Group>
        </Form>
        {errorMessage && <Message negative>
          <Message.Header>{errorMessage.title}</Message.Header>
          <p>{errorMessage.body}</p>
        </Message>}
      </>
    }

  </Container>
}

export default SubscribeToNewsletter;