import React from 'react'
import styled from 'styled-components'
import Link from 'next/link'
import Image from 'next/image'
import config from '../../services/config'

import MobileHamburger from './MobileHamburger'
import MemberMenu from './MemberMenu'
import { Button, Popup } from 'semantic-ui-react'
import layout from '../../services/layout'

const HeaderContainer = styled.header`
  background-color: #1B1B1B;
  padding: 0 20px;
  height: 80px;
  display: flex;
  align-items: center;
  position: sticky;
  top: 0;
  z-index: 20;
  border-bottom: 1px solid #3b3b3b;

  @media only screen and (max-width: ${layout.global.largeMax}) {
    height: auto;
    padding: 5px 10px;
  }
`

const LogoLink = styled(Link)`
  display: flex;
  position: relative;
  flex-shrink: 0;
  &:after {
    content: ' ';
    position: absolute;
    right: -50px;
    top: 0;
    bottom: 0;
    width: 50px;
    background: linear-gradient(-90deg, rgba(27, 27, 27, 0) 0%, rgba(27, 27, 27, 0.5) 20%, rgba(27, 27, 27, 1) 100%);
    z-index: 2;
  }

  @media only screen and (max-width: ${layout.global.largeMax}) {
    width: 165px;
  }

  @media only screen and (max-width: ${layout.global.mediumMax}) {
    width: 90px;
  }
  
  @media only screen and (max-width: ${layout.global.smallMax}) {
    &:after {
      content: none;
    }
  }
`

const HeaderLogoContainer = styled.div`
  &&&.desktop-only {
    display: block !important;
    @media (max-width: ${layout.global.mediumMax}) {
      display: none !important;
    }
  }

  &&&.non-desktop-only {
    display: none !important;
    @media (max-width: ${layout.global.mediumMax}) {
      display: block !important;
    }
  }
  
  @media only screen and (max-width: ${layout.global.largeMax}) {
    img {
      width: 100%;
      height: auto;
    }
  }
`

const NavList = styled.ul`
  padding: 0;
  margin: 0;
  display: flex;
  padding-left: 50px;
  align-items: center;
`

const NavMenu = styled.ul`
  padding: 0;
  width: max-content !important;
`

const NavItem = styled.li`
  white-space: nowrap;
  display: inline-block;
  list-style: none;
  padding-right: 40px;
  font-size: 110%;
  position: relative;
  > a {
    color: white;
  }

  &:last-child {
    padding-right: 80px;
  }
`

const NavMenuItem = styled.li`
  display: block;
  padding: 10px 20px;
  list-style: none;
  > a {
    color: white;
  }
`

const RightNavItem = styled(NavItem)`
  padding-right: 0;
  margin: 0 20px 0 auto;
  position: relative;
  &:after {
    content: ' ';
    position: absolute;
    left: -80px;
    top: 0;
    bottom: 0;
    width: 80px;
    background: linear-gradient(90deg, rgba(27, 27, 27, 0) 0%, rgba(27, 27, 27, 0.5) 20%, rgba(27, 27, 27, 1) 100%);
  }
  
  @media only screen and (max-width: ${layout.global.smallMax}) {
    button {
      height: 30px;
      lineHeight: 0;
      padding: 0 20px !important;
    }
    &:after {
      content: none;
    }
  }
  
`

const DesktopView = styled.span`
  flex: 100%;
  overflow-x: scroll;
  -ms-overflow-style: none;  // IE 10+
  overflow: -moz-scrollbars-none;  // Firefox
  &::-webkit-scrollbar {
    display: none; // Safari and Chrome
  }
  @media only screen and (max-width: ${layout.global.smallMax}) {
    display: none;
  }
`

const Clr = styled.div`
  clear: both;
`

function Header() {
  const utmTags = '?utm_source=topnav_link&utm_medium=web&utm_campaign=none';
  const analyticSourceTag = '?source=TOPNAV';

  return (
    <HeaderContainer>
      <MobileHamburger />
      <LogoLink href='/'>
        <HeaderLogoContainer className='desktop-only'>
          <Image src='/logo-without-tagline.png' alt='my105 - No. 1 Race car classfieds' width='249' height='46' />
        </HeaderLogoContainer>
        <HeaderLogoContainer className='non-desktop-only'>
          <Image src='/logo-without-tagline-without-dotcom.png' alt='my105 - No. 1 Race car classfieds' width='147' height='46' />
        </HeaderLogoContainer>
      </LogoLink>

      <DesktopView>
        <NavList>
          <Popup trigger={
            <NavItem>
              <Link href={'/search' + analyticSourceTag}>For Sale</Link>
            </NavItem>
          } hoverable inverted>
            <NavMenu>
              <NavMenuItem>
                <Link href={'/search/car-bike-kart' + analyticSourceTag}>Cars, Bikes &amp; Karts</Link>
              </NavMenuItem>
              <NavMenuItem>
                <Link href={'/search/trailer-transporter' + analyticSourceTag}>Trailers, Transporters &amp; Tow Vehicles</Link>
              </NavMenuItem>
              <NavMenuItem>
                <Link href={'/search/parts-accessories-other' + analyticSourceTag}>Parts &amp; Accessories</Link>
              </NavMenuItem>
            </NavMenu>
          </Popup>
          <Popup trigger={
            <NavItem>
              <Link href={config.get('SELL_SITE_URL') + utmTags}>Start selling</Link>
            </NavItem>
          } hoverable inverted>
            <NavMenu>
              <NavMenuItem>
                <Link href={config.get('SELL_SITE_URL') + utmTags}>Place New Ad</Link>
              </NavMenuItem>
              <NavMenuItem>
                <Link href={config.get('SELL_SITE_URL') + 'dashboard' + utmTags}>Manage Ad</Link>
              </NavMenuItem>
            </NavMenu>
          </Popup>
          <NavItem>
            <Link href='/about-my105'>Contact</Link>
          </NavItem>
          <NavItem>
            <Link href='/business/advertising'>Business Advertising</Link>
          </NavItem>
        </NavList>

      </DesktopView>

      <RightNavItem as='div'>
        <Link href={config.get('SELL_SITE_URL') + utmTags}>
          <Button color='grey' style={{ backgroundColor: layout.colors.accent }}>Start selling</Button>
        </Link>
      </RightNavItem>

      <MemberMenu />
      <Clr />
    </HeaderContainer>
  )
}

export default Header
