const tagLine = 'Australia\'s #1 Performance & Motorsport Marketplace';

export default {
  details: {
    desktopRHSWidthTotal: '430px',
    contentWidth: '1300px'
  },
  search: {
    contentWidth: '1600px',
    listViewLandscapeMaxWidth: '1400px',
    listViewLandscapeMaxWidthPixels: 1400,
    onCanvasFilterColumnMax: '925px',
    onCanvasFilterColumnMaxPixels: 925
  },
  adCard: {
    descriptionTruncationMaxWidth: '1550px',
    enquireButtonHideMaxWidth: '420px'
  },
  home: {
    searchWidgetMaxWidth: '900px',
    contentWidth: '1400px'
  },
  global: {
    mobileMax: '480px',
    smallMax: '768px',
    mediumMax: '1024px',
    largeMax: '1280px',
    imageRatio: '2 / 3',
    titleTagLine: tagLine,
    titleSuffix: ` - ${tagLine} - my105.com`,
    browseTitleSuffix: ' | my105.com',
    createEnquiryPlaceholder: (adTitle) => `Hi, I'm interested in your "${adTitle}" on my105.  When is a good day/time to inspect?`
  },
  colors: {
    primary: '#DA2128',
    accent: '#DA7721',
    secondary: '#767676'
  },
  buttons: {
    activeOutline: 'white',
    inactiveOutline: 'rgba(255,255,255,.5)',
    outlineThickness: '1px',
    outlineThicknessNumber: 2
  }
};