import React, { useEffect, useState } from 'react'
import { Dimmer, Dropdown, Icon, Loader } from 'semantic-ui-react'
import styled from 'styled-components'
import { useAuth } from '../../hooks/use-auth';
import Link from 'next/link';
import config from '../../services/config';
import { useRouter } from 'next/router'
import layout from '../../services/layout';
import historyService from '../../services/HistoryService';
import Script from 'next/script'

const MemberList = styled.ul`
  padding: 0;
  margin: 0;
  display: inline-block;
  white-space: nowrap;

  && .dropdown.icon {
    line-height: 5px;
    height: 15px;
    vertical-align: middle;
  }
`

const MemberItem = styled.li`
  display: inline-block;
  list-style: none;
  color: white;
  > a {
    color: white;
  }
  .menu .item {      
    a {
      color: black;
    }
  }
`

const LimitedWidthSpan = styled.span`
  max-width: 130px;
  text-overflow: ellipsis;
  display: inline-block;
  overflow: hidden;
  white-space: nowrap;

  && .icon {
    font-size: 20px;
    line-height: 36px;
    height: 30px;
  }
`

const MemberMenuLabel = styled.span`
  @media (max-width: ${layout.global.mobileMax}) {
    display: none;
  }
`

const DropdownItemWithLink = styled(Dropdown.Item)`
  &&&& {
    padding: 0 !important;
  }
  a {
    padding: 0.78571429rem 1.14285714rem !important;
    display: block;
  }
`

const completeRegistrationPath = '/authenticate/complete-registration';

function MemberMenu() {
  const [isLoading, setIsLoading] = useState(false);
  const auth = useAuth();
  const router = useRouter();

  const isLoadingCompleteRegistrationPath = () => {
    return router.pathname.startsWith(completeRegistrationPath);
  }

  // Unfortunately this needs to be within a component in order to use useHistory
  // useHistory will be undefined within our custom useAuth hook
  useEffect(() => {
    auth.registerEventHandlers({
      onCustomOAuthState: (user, nextLocation) => {
        setIsLoading(true);
        const handleRouteChangeComplete = () => {
          setIsLoading(false);
          router.events.off('routeChangeComplete', handleRouteChangeComplete)
          router.events.off('routeChangeError', handleRouteChangeComplete)
        }
        router.events.on('routeChangeComplete', handleRouteChangeComplete)
        router.events.on('routeChangeError', handleRouteChangeComplete)

        const requiresCompletion = user.attributes['custom:isCompleted'] !== 'true';

        if (requiresCompletion && !isLoadingCompleteRegistrationPath()) {
          // This social sign in hasn't been completed yet - take them to do that
          router.replace({
            pathname: completeRegistrationPath,
            query: {
              returnUrl: nextLocation
            }
          });
        }
        else {
          // This social sign in is all completed, just take them directly to where they tried to go
          router.replace(nextLocation || '/');
        }
      }
    });
  }, [auth, router]);


  useEffect(() => {
    const handleRouteChangeDone = (url) => {
      historyService.addUrl(router, url);
    }

    router.events.on('routeChangeComplete', handleRouteChangeDone)
    router.events.on('routeChangeError', handleRouteChangeDone)

    return () => {
      router.events.off('routeChangeComplete', handleRouteChangeDone)
      router.events.off('routeChangeError', handleRouteChangeDone)
    }
  }, [router]);

  return <MemberList>
    {isLoading && <Dimmer active style={{ position: 'fixed' }}>
      <Loader />
    </Dimmer>}
    <Script async src={'https://www.googletagmanager.com/gtag/js?id=' + config.get('GOOGLE_ANALYTICS_MEASUREMENT_ID')} />
    <Script
      id="gtag-container-div"
      dangerouslySetInnerHTML={{
        __html: `
            window.dataLayer = window.dataLayer || [];
            function gtag() { dataLayer.push(arguments); }
            gtag('js', new Date());
            gtag('config', "${config.get('GOOGLE_ANALYTICS_MEASUREMENT_ID')}");
            window.gtag = gtag;
          `,
      }}
    />
    {auth.user && auth.user.authenticated
      ? <MemberItem>
        <Dropdown direction="left"
          aria-label='Member Menu'
          trigger={
            <LimitedWidthSpan>
              <Icon name='user' /> <MemberMenuLabel>{auth.user.name}</MemberMenuLabel>
            </LimitedWidthSpan>}>
          <Dropdown.Menu>
            <Dropdown.Item disabled>
              <p>
                Signed in as {auth.user.name}
              </p>
              <strong>{auth.user.email}</strong>
            </Dropdown.Item>
            <Dropdown.Divider />
            <Dropdown.Header>Buying</Dropdown.Header>
            <DropdownItemWithLink>
              <Link href='/member/watchlist'>Watchlist</Link>
            </DropdownItemWithLink>
            <DropdownItemWithLink>
              <Link href={'/member/saved-searches/' + auth.user.userId}>Saved Searches</Link>
            </DropdownItemWithLink>
            <Dropdown.Divider />
            <Dropdown.Header>Selling</Dropdown.Header>
            <DropdownItemWithLink>
              <Link href={config.get('SELL_SITE_URL')}>Place New Ad</Link>
            </DropdownItemWithLink>
            <DropdownItemWithLink>
              <Link href={config.get('SELL_SITE_URL') + 'dashboard'}>Manage Ad</Link>
            </DropdownItemWithLink>
            <Dropdown.Divider />
            <Dropdown.Header>Account</Dropdown.Header>
            <DropdownItemWithLink>
              <Link href={config.get('SELL_SITE_URL') + 'profile'}>Profile</Link>
            </DropdownItemWithLink>
            <DropdownItemWithLink>
              <Link href={'/member/notification-settings/' + auth.user.userId}>Notification Settings</Link>
            </DropdownItemWithLink>
            <Dropdown.Item onClick={auth.signout}>
              Sign Out
            </Dropdown.Item>
            {auth.user['custom:isAdmin'] === 'true' && <>
              <Dropdown.Divider />
              <Dropdown.Header><Icon name='lock' /> Admin</Dropdown.Header>
              <DropdownItemWithLink>
                <Link href={config.get('SELL_SITE_URL') + 'admin'}>Administration</Link>
              </DropdownItemWithLink>
              <DropdownItemWithLink>
                <Link href={config.get('SELL_SITE_URL') + 'admin-analytics'}>Analytics</Link>
              </DropdownItemWithLink>
            </>}
          </Dropdown.Menu>
        </Dropdown>
      </MemberItem>
      : <MemberItem>
        <Link href={{
          pathname: '/authenticate',
          query: router.asPath && router.asPath.startsWith('/authenticate') ?
            router.query
            : {
              returnUrl: router.asPath
            }
        }}>
          <LimitedWidthSpan>
            <Icon name='user' /> <MemberMenuLabel>Sign In / Join</MemberMenuLabel>
          </LimitedWidthSpan>
        </Link>
      </MemberItem>
    }
  </MemberList>
}

export default MemberMenu
