import React, { useState } from 'react'
import Link from 'next/link'
import Image from 'next/image'
import styled from 'styled-components'
import { Icon, Portal } from 'semantic-ui-react'
import layout from '../../services/layout'
import config from '../../services/config'

const MobileView = styled.span`
  display: none;
  @media only screen and (max-width: ${layout.global.largeMax}) {    
    display: block;
  }
  margin-top: 3px;
  margin-right: 10px;
`

const MenuContainer = styled.nav`
  position: fixed;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  background-color: #1B1B1B;
  z-index: 60;
`

const MenuHeader = styled.div`
  padding: 20px;
  text-align: center;
`

const MenuList = styled.ul`
  margin: 0;
  padding: 0;
`

const MenuItem = styled.li`
  list-style: none;
  a {
    display: block;
    color: white;
    padding: 20px 30px;
    border-top: 1px solid #555;
    font-size: 130%;

    &:hover {
      background-color: #2b2b2b;
    }
  }
`

const LogoImage = styled(Image)`
  margin: 0 auto;
`
const CloseIcon = styled.span`
  display: inline-block;
  cursor: pointer;
  float: right;
  font-size: 30px;
  color: white;
  margin-top: 5px;
`

function MobileHamburger() {
  const [menuOpen, setMenuOpen] = useState(false);
  const utmTags = '?utm_source=mobile_burger&utm_medium=web&utm_campaign=none';
  const analyticSourceTag = '?source=MOBILE_BURGER';

  return (
    <MobileView>
      <Icon size='large' color='red' name='bars' onClick={() => setMenuOpen(true)} />
      <Portal open={menuOpen}>
        <MenuContainer>
          <MenuHeader>
            <Link href={'/'} onClick={() => setMenuOpen(false)}>
              <LogoImage src='/logo-without-tagline.png' width='180' height='33' />
            </Link>
            <CloseIcon onClick={() => setMenuOpen(false)}>&#9587;</CloseIcon>
          </MenuHeader>
          <MenuList>
            <MenuItem>
              <Link href={'/search/car-bike-kart' + analyticSourceTag} onClick={() => setMenuOpen(false)}>Cars, Bikes &amp; Karts</Link>
            </MenuItem>
            <MenuItem>
              <Link href={'/search/trailer-transporter' + analyticSourceTag} onClick={() => setMenuOpen(false)}>Trailers, Transporters &amp; Tow Vehicles</Link>
            </MenuItem>
            <MenuItem>
              <Link href={'/search/parts-accessories-other' + analyticSourceTag} onClick={() => setMenuOpen(false)}>Parts &amp; Accessories</Link>
            </MenuItem>
            <MenuItem>
              <Link href={config.get('SELL_SITE_URL') + utmTags} onClick={() => setMenuOpen(false)}>Place New Ad</Link>
            </MenuItem>
            <MenuItem>
              <Link href={config.get('SELL_SITE_URL') + 'dashboard' + utmTags} onClick={() => setMenuOpen(false)}>Manage Your Ad</Link>
            </MenuItem>
            <MenuItem>
              <Link href='/about-my105' onClick={() => setMenuOpen(false)}>Contact</Link>
            </MenuItem>
            <MenuItem>
              <Link href='/business/advertising' onClick={() => setMenuOpen(false)}>Business Advertising</Link>
            </MenuItem>
          </MenuList>
        </MenuContainer>
      </Portal>
    </MobileView>
  )
}

export default MobileHamburger
